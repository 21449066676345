import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import WebriQForm from '@webriq/gatsby-webriq-form'
import MySwiper from '../components/Swiper'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'


class IndexPage extends React.Component {
 
    
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />
        <div>
          <p>This is the homepage</p>
          <Link to="/blog">Go to Blog</Link>
          <br />
          <br />
          <h1>Sample Swiper</h1>
          <br />
          <MySwiper slidesperview={3}>
            <div><LazyLoadImage effect="blur" className="img-fluid" src="https://source.unsplash.com/collection/145698/400x400" alt=""/></div>
            <div><LazyLoadImage effect="blur" className="img-fluid" src="https://source.unsplash.com/collection/2203755/400x400" alt=""/></div>
            <div><LazyLoadImage effect="blur" className="img-fluid" src="https://source.unsplash.com/collection/362271/400x400" alt=""/></div>
            <div><LazyLoadImage effect="blur" className="img-fluid" src="https://source.unsplash.com/collection/3657445/400x400" alt=""/></div>
            <div><LazyLoadImage effect="blur" className="img-fluid" src="https://source.unsplash.com/collection/385548/400x400" alt=""/></div>
            <div><LazyLoadImage effect="blur" className="img-fluid" src="https://source.unsplash.com/collection/325867/400x400" alt=""/></div>
            <div><LazyLoadImage effect="blur" className="img-fluid" src="https://source.unsplash.com/collection/1118905/400x400" alt=""/></div>
            <div><LazyLoadImage effect="blur" className="img-fluid" src="https://source.unsplash.com/collection/2270935/400x400" alt=""/></div>
            <div><LazyLoadImage effect="blur" className="img-fluid" src="https://source.unsplash.com/collection/1252081/400x400" alt=""/></div>
            <div><LazyLoadImage effect="blur" className="img-fluid" src="https://source.unsplash.com/collection/2411320/400x400" alt=""/></div>
          </MySwiper>
          <br />
          <br />
          <div className="body-content py-5">
						<div className="container">
							<div className="row">
								<div className="col-12 col-md-12 mt-4">
									<div className="contact-form">
										<WebriQForm
											name="Home Contact"
											method="POST"
											id="contact"
											data-form-id="0e4550cb-3c29-4a89-94a1-dd05320c9482"
                      data-thankyou-url="/thank-you"
                      scriptSrc="https://t2zw3gn0k8.execute-api.us-west-2.amazonaws.com/dev/js/initReactForms"
										>
											<div class="form-group">
												<input
													type="text"
													class="form-control"
													id="Name"
													aria-describedby="Name"
													placeholder="Name"
												/>
											</div>
											<div class="form-group">
												<input
													type="email"
													class="form-control"
													id="exampleInputEmail1"
													aria-describedby="Email"
													placeholder="Enter email"
												/>
											</div>
											<div class="form-group">
												<input
													type="text"
													class="form-control"
													id="Subject"
													placeholder="Subject"
												/>
											</div>
											<div class="form-group">
												<textarea
													class="form-control"
													id="Message"
													rows="7"
													placeholder="Message"
												></textarea>
											</div>
											<div class="form-group">
												<div className="webriq-recaptcha mb-3" />
											</div>
											<button type="submit" class="btn btn-primary submit">
												Submit
											</button>
										</WebriQForm>
									</div>
								</div>
							</div>
						</div>
					</div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
